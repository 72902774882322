import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import useSWR, { useSWRConfig, type KeyLoader } from "swr"
import useSWRInfinite from "swr/infinite"

const isMultiTenant = () => process.env.PRACTICE_ID === undefined

export const AxiosBase = axios.create({
  withCredentials: isMultiTenant(),
})

export const fetcher = async (
  url = "",
  getAccessTokenSilently = undefined,
  timeout = 30000,
) => {
  const accessToken = getAccessTokenSilently
    ? await getAccessTokenSilently()
    : undefined
  const controller = new AbortController()
  const timeoutFn = setTimeout(() => {
    controller.abort()
  }, timeout)
  const response = await AxiosBase.get(url, {
    signal: controller.signal,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  clearTimeout(timeoutFn)
  return response.data
}

export const postFetcher = async (
  url = "",
  body = {},
  getAccessTokenSilently = undefined,
  timeout = 30000,
) => {
  const accessToken = getAccessTokenSilently
    ? await getAccessTokenSilently()
    : undefined
  const controller = new AbortController()
  const timeoutFn = setTimeout(() => {
    controller.abort()
  }, timeout)
  const response = await AxiosBase.post(url, body, {
    signal: controller.signal,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  })
  clearTimeout(timeoutFn)
  return response.data
}

export const useIndex = (resource, params = null, options = {}) => {
  const { getAccessTokenSilently } = useAuth0()

  const swrOptions = {
    fetcher: fetcher,
    fallbackData: { data: [], fallback: true },
    ...options,
  }

  const { data, error, mutate } = useSWR(
    [
      `${process.env.API_URL}/${resource}${params ? `?${params}` : ""}`,
      getAccessTokenSilently,
      options?.timeout,
    ],
    swrOptions,
  )

  return {
    data: data?.data,
    mutate: mutate,
    isLoading: !error && !!data.fallback,
    isError: error,
  }
}

export const useIndexInfinite = (
  resource,
  params = null,
  options = {},
  fetcherFunc = fetcher,
) => {
  const pageSize = options.pageSize
  const { getAccessTokenSilently } = useAuth0()

  const getKey: KeyLoader = (pageIndex, previousPageData) => {
    if (!resource) {
      return false
    }

    // reached the end
    if (
      pageIndex > 0 &&
      (!previousPageData || previousPageData?.data?.length === 0)
    ) {
      return null
    }
    // offset = 0 if pageIndex = 0, otherwise is calculated
    const offset = pageIndex * pageSize

    const url = `${indexUrl(resource)}?offset=${offset}&limit=${pageSize}${
      params ? `&${params}` : ""
    }`
    return [url, getAccessTokenSilently]
  }

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(
    getKey,
    fetcherFunc,
  )

  return { data, error, isValidating, mutate, size, setSize }
}

export const useGetIndexInfinite = (resource, params = null, options = {}) => {
  return useIndexInfinite(
    resource,
    params,
    options,
    (url, getAccessTokenSilently) => fetcher(url, getAccessTokenSilently),
  )
}

export const usePostIndexInfinite = (resource, params = null, options = {}) => {
  return useIndexInfinite(
    resource,
    params,
    options,
    (url, getAccessTokenSilently) =>
      postFetcher(url, options.body, getAccessTokenSilently),
  )
}

export function useShow(resource, id, options = {}) {
  const { getAccessTokenSilently } = useAuth0()

  const swrOptions = {
    fetcher: fetcher,
    fallbackData: { data: {}, fallback: true },
    ...options,
  }
  const { data, error, mutate } = useSWR(
    id && [showURL(resource, id), getAccessTokenSilently, options?.timeout],
    swrOptions,
  )

  return {
    data: data?.data,
    mutate: mutate,
    isLoading: !error && !!data.fallback,
    isError: error,
  }
}

export function showURL(resource, id) {
  return `${process.env.API_URL}/${resource}/${id}`
}

export function indexUrl(resource) {
  return `${process.env.API_URL}/${resource}`
}

export function createUrl(resource) {
  return indexUrl(resource)
}

export function updateUrl(resource, id) {
  return showURL(resource, id)
}

export const useGet = async (resource, id, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently()
  return await AxiosBase.get(showURL(resource, id), {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const useCreate = async (resource, data, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently()
  return await AxiosBase.post(createUrl(resource), data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const useUpdate = async (resource, id, data, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently()
  return await AxiosBase.patch(updateUrl(resource, id), data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const useDelete = async (resource, id, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently()
  return await AxiosBase.delete(updateUrl(resource, id), {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export function useMatchMutate() {
  const { cache, mutate } = useSWRConfig()
  return (matcher, ...args) => {
    if (!(cache instanceof Map)) {
      throw new Error(
        "matchMutate requires the cache provider to be a Map instance",
      )
    }

    const keys = []

    for (const key of cache.keys()) {
      if (matcher.test(key)) {
        keys.push(key)
      }
    }

    const mutations = keys.map((key) => mutate(key, ...args))
    // console.dir(mutations)
    return Promise.all(mutations)
  }
}
