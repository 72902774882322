import { useAuth0 } from "@auth0/auth0-react"
import { XCircleIcon } from "@heroicons/react/solid"
import { Drawer, Spinner } from "flowbite-react"
import { useContext, useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { SyncLoader } from "react-spinners"
import { HeroIcon } from "../../components/hero-icon"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { useCreate } from "../../shared/use-rest"
import { useIndexQuery, useShowQuery } from "../../shared/use-rest-query"
import { ChatBubble } from "./chat-bubble"

export function ChatDrawer(props) {
  const { isOpen, setIsOpen, patientId } = props
  const {
    data: { data: patient },
    isFetching,
    isLoading,
    isError,
  } = useShowQuery("patients", patientId)
  const { getAccessTokenSilently } = useAuth0()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm()
  const { currentUser, practiceId } = useContext(AuthorizationContext)
  const phone = patient?.preferred_phone || patient?.phone

  const onSubmit = async (data) => {
    data.patient_id = props.patientId

    await useCreate("secure_messages", data, getAccessTokenSilently)
    event({
      eventName: "PatientSecureMessageCreate",
      meta: {
        patient_id: patientId,
        element: "ChatDrawer",
      },
      getAccessTokenSilently,
    })
    // secureMessagesMutate()
    reset()
    // wait for paubox
    setTimeout(refetch, 500)
    // callback
    props.onMessageSent()
  }

  const {
    data: { data: messages },
    refetch,
  } = useIndexQuery(
    `secure_messages?patient_id=${patientId}`,
    null,
    {},
    { refetchOnWindowFocus: true },
  )

  console.dir(messages)

  const handleClose = () => setIsOpen(false)

  const messagesEndRef = useRef(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: is open should retrigger fetch
  useEffect(() => {
    refetch()
  }, [isOpen])

  // biome-ignore lint/correctness/useExhaustiveDependencies: auto scroll on messages change
  useEffect(() => {
    // Scroll to the bottom of the element whenever the messages change
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
  }, [messages, isOpen])

  useEffect(() => {
    reset({ phone_number: patient?.preferred_phone || patient?.phone })
  }, [patient, reset])

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        position="right"
        className="w-1/5"
        backdrop={false}
      >
        <Drawer.Items>
          {/* Drawer Header */}
          <div className="flex items-center mb-4">
            <h3 className="flex-1 text-lg font font-semibold">Messages</h3>
            <div
              className="flex h-5 hover:cursor-pointer"
              onClick={handleClose}
              onKeyUp={handleClose}
            >
              <XCircleIcon />
            </div>
          </div>

          {false && !patientId && (
            <div>
              <form>
                <input
                  className="mr-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                  type="text"
                />
                <button
                  type="submit"
                  className="align-middle bg-[#0227FB] hover:bg-blue-800 rounded focus:ring-blue-300 h-10 p-2 rotate-90"
                >
                  <HeroIcon className="h-5 w-5 text-white" icon="SearchIcon" />
                </button>
              </form>
            </div>
          )}

          {/* Message Bubbles */}
          <div className="space-y-6">
            {messages[Object.keys(messages)?.[0]]
              ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((message) => (
                <ChatBubble
                  key={message?.id}
                  message={message}
                  patient={patient}
                />
              ))}
          </div>
          <div className="text-xs mb-2">
            {isFetching && <SyncLoader size={5} />}
          </div>

          {/* Message Form */}
          <div className="mt-4">
            {phone && (
              <div className="text-sm grey-400 flex">
                Sending to {patient?.preferred_phone || patient?.phone}
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1">
              <input type="hidden" {...register("phone_number")} />
              <textarea
                {...register("message")}
                rows={1}
                disabled={isSubmitting}
                required
                className="p-2 w-full self-center min-h-[2.3rem] text-sm bg-white dark:bg-gray-800 border border-gray-200 shadow-sm rounded-md mr-2"
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className="align-middle bg-[#0227FB] hover:bg-blue-800 rounded focus:ring-blue-300 h-10 p-2 rotate-90"
              >
                {isSubmitting && <Spinner size={"sm"} />}
                <HeroIcon
                  className="h-5 w-5 text-white"
                  icon="PaperAirplaneIcon"
                />
              </button>
            </form>
          </div>
          <div ref={messagesEndRef} />
        </Drawer.Items>
      </Drawer>
    </>
  )
}
