import _extends from "@babel/runtime/helpers/esm/extends";
import { GRID_STRING_COL_DEF } from '@mui/x-data-grid';

/**
 * TODO: Add sorting and filtering on the value and the filteredDescendantCount
 */
export const GRID_TREE_DATA_GROUPING_COL_DEF = _extends({}, GRID_STRING_COL_DEF, {
  type: 'custom',
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableReorder: true,
  align: 'left',
  width: 200,
  valueGetter: (value, row, column, apiRef) => {
    const rowId = apiRef.current.getRowId(row);
    const rowNode = apiRef.current.getRowNode(rowId);
    return rowNode?.type === 'group' || rowNode?.type === 'leaf' ? rowNode.groupingKey : undefined;
  }
});
export const GRID_TREE_DATA_GROUPING_FIELD = '__tree_data_group__';
export const GRID_TREE_DATA_GROUPING_COL_DEF_FORCED_PROPERTIES = {
  field: GRID_TREE_DATA_GROUPING_FIELD,
  editable: false,
  groupable: false
};