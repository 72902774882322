import { useAuth0 } from "@auth0/auth0-react"
import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { Alert } from "../../../../components/alert"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { SaveSpinner } from "../../../../components/save-spinner"
import { Spinner } from "../../../../components/spinner"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { AxiosBase, createUrl, useUpdate } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { StudyEditModal } from "../edit-modal"
import { StudyNewSlideover } from "../new-slideover"

export function VisitAdmin() {
  const { getAccessTokenSilently } = useAuth0()
  const [editingStudyVisitId, setEditingStudyVisitId] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [saved, setSaved] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { studyId, visitId } = useParams()
  const {
    data: { data: visitData },
    isFetching: visitIsFetching,
    isError: visitIsError,
  } = useIndexQuery(`admin/study_visits/${visitId}`)

  const {
    data: { data: userData },
  } = useIndexQuery("admin/users")

  const {
    data: { data: tasksData },
    isLoading,
    isError,
  } = useIndexQuery("tasks", `task_template_id=${visitData?.task_template_id}`)

  const queryClient = useQueryClient()

  const mutateStudyVisits = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/study_visits", `study_id=${visitId}`],
    })
  }
  const rows: GridRowsProp = tasksData
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: "Description",
      width: 200,
      display: "flex",
    },
    {
      field: "task_due_date_offset_from_visit",
      headerName: "Due Offset",
      width: 120,
      display: "flex",
    },
    {
      field: "index",
      headerName: "Order",
      width: 80,
      display: "flex",
    },
    {
      field: "team_assignment",
      headerName: "Team",
      width: 120,
      display: "flex",
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  const renderUpdatedBy = (props: GridCellParams) => {
    let updatedBy = userData.find(
      (user) => user.id === props.row.task_template.updated_by_user_id,
    )
    if (!updatedBy) {
      updatedBy = userData.find(
        (user) => user.id === props.row.task_template.created_by_user_id,
      )
    }
    return <>{updatedBy.name}</>
  }

  const taskColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Task Name",
      width: 250,
      display: "flex",
    },
    ...columns,
    {
      field: "updated_by",
      headerName: "Updated By",
      width: 150,
      display: "flex",
      flex: 1,
      renderCell: (params) => renderUpdatedBy(params),
    },
  ]

  const subtaskColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Subtask Name",
      width: 250,
      display: "flex",
    },
    ...columns,
  ]

  const handleUpload = async (e) => {
    setIsSaving(true)
    const file = e.target.files[0]
    const formData = new FormData()
    const accessToken = await getAccessTokenSilently()
    formData.append("csv_file", file)
    // post task_template_import
    const createResponse = await AxiosBase.post(
      createUrl("admin/task_template_import"),
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-type": "multipart/form-data",
        },
      },
    ).catch((error) => {
      setIsSaving(false)
      if (error.response && error.response.status === 422) {
        setErrorMessage(error.response.data.message)
      } else {
        throw error // Re-throw other errors to maintain the flow
      }
    })

    if (createResponse) {
      // get task_template_id from response
      const taskTemplateId = createResponse?.data?.data?.id
      // patch study_visit w/ task_template_id
      const updateResponse = await useUpdate(
        "admin/study_visits",
        visitId,
        { task_template_id: taskTemplateId },
        getAccessTokenSilently,
      )
      if (updateResponse.status === 200) {
        setSaved(true)
        setEditingStudyVisitId(null)
      }
    }
    setIsSaving(false)
    mutateStudyVisits()
  }

  return (
    <>
      <ActionHeader
        title={`${
          visitData?.visit_name?.charAt(0)?.toUpperCase() +
          visitData?.visit_name?.slice(1)
        } Visit Tasks`}
      >
        {saved && <SaveSpinner isShowing={saved} />}
        {isSaving && (
          <div className="flex ml-4 items-center text-black dark:text-gray-300 text-md">
            <Spinner /> Saving...
          </div>
        )}
        {errorMessage && <Alert header="Upload Error" message={errorMessage} />}
        <div className="flex-1 items-start m-1">
          {editingStudyVisitId !== visitData.id && (
            <div
              onClick={() => setEditingStudyVisitId(visitData.id)}
              onKeyUp={() => setEditingStudyVisitId(visitData.id)}
            >
              <ButtonLeadingIcon
                icon="PlusCircleIcon"
                text={`${visitData.task_template_id ? "Update" : "Add"} Tasks`}
              />
            </div>
          )}
          {editingStudyVisitId === visitData.id && (
            <input
              onChange={handleUpload}
              className="block text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              id="default_size"
              type="file"
            />
          )}
        </div>
      </ActionHeader>
      {isError ? "Failed to load" : ""}
      {visitIsFetching ? (
        <Spinner />
      ) : (
        <div className="mt-2 flex min-h-[200px]">
          <div className="flex-grow">
            <DataGridPro
              rows={rows}
              columns={taskColumns}
              getDetailPanelContent={({ row }) => (
                <div>
                  <DataGridPro rows={row.subtasks} columns={subtaskColumns} />
                </div>
              )}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}
