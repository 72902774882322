"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLowerCase = exports.isUpperCase = exports.upperCaseFirst = exports.localeUpperCase = exports.upperCase = exports.titleCase = exports.swapCase = exports.spongeCase = exports.lowerCaseFirst = exports.localeLowerCase = exports.lowerCase = exports.snakeCase = exports.sentenceCase = exports.pathCase = exports.pascalCase = exports.paramCase = exports.noCase = exports.headerCase = exports.dotCase = exports.constantCase = exports.capitalCase = exports.camelCase = void 0;
var changeCase = require("change-case");
var lowerCase1 = require("lower-case");
var lowerCaseFirst1 = require("lower-case-first");
var spongeCase1 = require("sponge-case");
var swapCase1 = require("swap-case");
var titleCase1 = require("title-case");
var upperCase1 = require("upper-case");
var upperCaseFirst1 = require("upper-case-first");
var isUpperCase1 = require("is-upper-case");
var isLowerCase1 = require("is-lower-case");
// Core
exports.camelCase = changeCase.camelCase;
exports.capitalCase = changeCase.capitalCase;
exports.constantCase = changeCase.constantCase;
exports.dotCase = changeCase.dotCase;
exports.headerCase = changeCase.headerCase;
exports.noCase = changeCase.noCase;
exports.paramCase = changeCase.paramCase;
exports.pascalCase = changeCase.pascalCase;
exports.pathCase = changeCase.pathCase;
exports.sentenceCase = changeCase.sentenceCase;
exports.snakeCase = changeCase.snakeCase;
// Extended
exports.lowerCase = lowerCase1.lowerCase;
exports.localeLowerCase = lowerCase1.localeLowerCase;
exports.lowerCaseFirst = lowerCaseFirst1.lowerCaseFirst;
exports.spongeCase = spongeCase1.spongeCase;
exports.swapCase = swapCase1.swapCase;
exports.titleCase = titleCase1.titleCase;
exports.upperCase = upperCase1.upperCase;
exports.localeUpperCase = upperCase1.localeUpperCase;
exports.upperCaseFirst = upperCaseFirst1.upperCaseFirst;
exports.isUpperCase = isUpperCase1.isUpperCase;
exports.isLowerCase = isLowerCase1.isLowerCase;
