import { useAuth0 } from "@auth0/auth0-react"
import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { Button, Tooltip } from "flowbite-react"
import { useState } from "react"
import type { MouseEvent } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { Alert } from "../../../components/alert"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { SaveSpinner } from "../../../components/save-spinner"
import { Spinner } from "../../../components/spinner"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useCreate, useIndex } from "../../../shared/use-rest"

export function SyncConfiguration() {
  const { getAccessTokenSilently } = useAuth0()
  const [showSave, setShowSave] = useState(false)
  const [error, setError] = useState("")

  const handleSyncConfiguration = async () => {
    try {
      const response = await useCreate(
        "admin/pipelines",
        {
          pipeline_name: "sync_practice_waypoints_multitenant_tables",
        },
        getAccessTokenSilently,
      )
      if (response.status === 200) {
        setShowSave(true)
      }
    } catch (error) {
      setError(error.message)
    }
  }

  const saveButton = () => {}
  return (
    <>
      <div className="flex justify-between items-center pr-4">
        <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
          Sync Configuration
        </h2>
      </div>
      <div className="max-w-50 m-4">
        <div className="mb-4 mt-4 max-w-prose">
          The Network Configuration must be synced to all of the practices.
          Clicking "Sync Configuration" will initiate a background task that
          takes approximately 15 minutes to sync the configuration.
        </div>
        <div
          className="flex items-center"
          onClick={handleSyncConfiguration}
          onKeyUp={handleSyncConfiguration}
        >
          {showSave ? (
            <Tooltip content="You have already synced the content!">
              <ButtonLeadingIcon
                icon="ShareIcon"
                text="Sync Configuration"
                className="opacity-50 cursor-not-allowed inline-flex items-center justify-center h-10 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              />
            </Tooltip>
          ) : (
            <div className="flex items-center">
              <ButtonLeadingIcon
                icon="ShareIcon"
                text="Sync Configuration"
                className="inline-flex items-center justify-center h-10 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              />
              {error && (
                <Alert
                  className="ml-4 inline-flex"
                  header="Upload Error"
                  message={error}
                />
              )}
            </div>
          )}
          {showSave && <SaveSpinner className="ml-4 inline-flex" />}
        </div>
      </div>
    </>
  )
}
