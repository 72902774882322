import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { featureFlagEnabled } from "../shared/feature-flag-provider"
import { useIndexQuery } from "../shared/use-rest-query"
import { ChatDrawer } from "./messaging/chat-drawer"
import { MessagesTable } from "./messaging/messages-table"
import { Patients } from "./patients"

export function Messaging() {
  const [chatDrawerIsOpen, setChatDrawerIsOpen] = useState(false)
  const [patientId, setPatientId] = useState("")
  const [studyId, setStudyId] = useState("")

  const { register, handleSubmit, reset } = useForm()

  // biome-ignore lint/correctness/useExhaustiveDependencies: refetch when study id changes
  useEffect(() => {
    refetch()
  }, [studyId])

  const {
    data: { data: messages },
    refetch,
  } = useIndexQuery(
    `secure_message_summaries?study_id=${studyId}`,
    "",
    {},
    { refetchOnWindowFocus: true },
  )
  const {
    data: { data: practiceStudies },
    refetch: refetchPracticeStudies,
  } = useIndexQuery("practice_studies", "", {}, { refetchOnWindowFocus: true })

  const handleStudyChange = (event) => {
    setStudyId(event.target.value ? event.target.value : null)
  }

  const { practiceName } = useContext(AuthorizationContext)
  if (
    !featureFlagEnabled("messaging") ||
    !["Woodholme", "Huron Gastro", "Rocky Mountain Gastroenterology"].includes(
      practiceName,
    )
  ) {
    return (
      <div className="p-4 font-semibold">
        This feature has not been enabled yet. Please contact an administrator.
        🤙
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center py-2">
        {/* <form className="flex items-center" onSubmit={() => ({})}>
          <select
            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
            // {...register("study_id")}
            onChange={handleStudyChange}
            id="study_id"
          >
            <option value="">All Studies</option>
            {practiceStudies.map((practiceStudy) => (
              <option
                key={practiceStudy?.study?.id}
                value={practiceStudy?.study?.id}
                title={practiceStudy?.study?.name}
              >
                {practiceStudy?.study?.abbreviation}
              </option>
            ))}
          </select>
        </form> */}
      </div>

      <MessagesTable
        messages={messages}
        setPatientId={setPatientId}
        setChatDrawerIsOpen={setChatDrawerIsOpen}
      />
      <ChatDrawer
        isOpen={chatDrawerIsOpen}
        setIsOpen={setChatDrawerIsOpen}
        patientId={patientId}
        onMessageSent={refetchPracticeStudies}
      />
    </>
  )
}
