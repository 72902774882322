import { useAuth0 } from "@auth0/auth0-react"
import { Tooltip } from "flowbite-react"
import { type Dispatch, type SetStateAction, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import type { KeyedMutator } from "swr"
import { HeroIcon } from "../../../../components/hero-icon"
import { useCreate, useShow, useUpdate } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { useKeyPress } from "../../../../shared/useKeyPress"

interface Props {
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  refreshData?: KeyedMutator<{ data: any[] }>
}
export function PracticeStudyNewModal(props: Props) {
  const { studyId } = useParams()
  const {
    data: { data: practiceStudies },
  } = useIndexQuery("admin/practice_studies", `study_id=${studyId}`)
  const {
    data: { data: practices },
  } = useIndexQuery("admin/practices")

  const practiceOptions = practices
    .map((practice) => {
      if (
        !practiceStudies.some(
          (practiceStudy) => practiceStudy.practice_id === practice.id,
        )
      ) {
        return {
          key: practice.id,
          label: `${practice.name} (${practice.abbreviation})`,
        }
      }
      return {
        key: practice.id,
        label: `${practice.name} (${practice.abbreviation})`,
        disabled: true,
      }
    })
    .filter((practice) => practice !== undefined)

  const { register, handleSubmit, reset } = useForm({
    defaultValues: { study_id: studyId },
  })
  const { getAccessTokenSilently } = useAuth0()

  const closeModal = () => {
    props.setOpen(false)
  }

  useKeyPress(["Escape"], closeModal)

  const onSubmit = (data) => {
    useCreate("admin/practice_studies", data, getAccessTokenSilently).then(
      (response) => {
        props.setOpen(false)
        props?.refreshData()
        reset()
      },
      (error) => {
        // console.dir(error);
      },
    )
  }

  const entity = {
    label: "Practice Study",
    key: "practice_study",
  }

  const fields = [
    {
      label: "Practice",
      key: "practice_id",
      required: true,
      options: practiceOptions,
    },
    {
      label: "Topo Study Id",
      key: "topography_study_identifier",
      tooltip:
        "This id matches the Salesforce Opportunity Id and the CRIO Internal Study Number.",
    },
    {
      label: "Status",
      key: "status",
      required: true,
      options: [
        { label: "Contracting", key: "Contracting" },
        { label: "Prescreening", key: "Prescreening" },
        { label: "Screening", key: "Screening" },
        { label: "Enrolling", key: "Enrolling" },
        { label: "Maintenance", key: "Maintenance" },
        { label: "Closed", key: "Closed" },
      ],
    },
    {
      label: "Esource Study Id",
      key: "xref_study_id",
      display: "true",
    },
    {
      label: "Screening Visit Id",
      key: "xref_study_visit_id",
      display: "true",
    },
    // TODO: add datepicker
    // { label: "Enrollment Start Date", key: "enrollment_start_dt" },
    // { label: "Enrollment End Date", key: "enrollment_end_dt" },
    {
      label: "Priority",
      key: "priority",
      options: [
        { label: "Low", key: "low" },
        { label: "Medium", key: "medium" },
        { label: "High", key: "high" },
      ],
    },
    // { label: "Enrollment Target", key: "enrollment_target" },
  ]

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="new-practice-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  New {entity.label}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => props.setOpen(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>new {entity.label}</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  {fields.map((field) => (
                    <div key={field.key} className="col-span-6 sm:col-span-6">
                      <div className="inline-flex">
                        <label
                          htmlFor={field.key}
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {field.label}
                        </label>

                        {field.tooltip && (
                          <Tooltip content={field.tooltip}>
                            <HeroIcon
                              icon="QuestionMarkCircleIcon"
                              className="h-4 ml-2 rounded-md"
                            />
                          </Tooltip>
                        )}
                      </div>
                      {!field.options && (
                        <input
                          {...register(field.key)}
                          type="text"
                          name={field.key}
                          id={field.key}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      )}
                      {field.options && (
                        <select
                          {...register(field.key)}
                          name={field.key}
                          id={field.key}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option key="default" value="" disabled selected>
                            Select an Option
                          </option>
                          {field.options.map((option) => (
                            <option
                              key={option?.label}
                              value={option.key}
                              disabled={option?.disabled || false}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ))}
                </div>
                {/* <!-- Modal footer --> */}
                <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                  <button
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        onClick={() => closeModal()}
        onKeyUp={() => closeModal()}
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
