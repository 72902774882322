import type {
  GridCellParams,
  GridColDef,
  GridRowsProp,
  MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import type { MouseEvent } from "react"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { Spinner } from "../../../components/spinner"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"
import { SponsorEditModal } from "./edit-modal"
import { SponsorNewModal } from "./new-modal"

export function SponsorsAdmin() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [newSlideoverOpen, setNewSlideoverOpen] = useState(
    searchParams.get("new") === "true",
  )
  const [newModalOpen, setNewModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [sponsorId, setSponsorId] = useState("")
  const { data, isLoading, isError, mutate } = useIndex("admin/sponsors")
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    {
      field: "logo_url",
      headerName: "Logo",
      width: 70,
      display: "flex",
      renderCell: (params) => (
        <img src={params.row.logo_url} alt="sponsor logo" />
      ),
    },
    { field: "name", headerName: "Name", width: 250, display: "flex" },
    { field: "abbreviation", headerName: "Abbr", width: 150, display: "flex" },
    { field: "location", headerName: "Location", width: 250, display: "flex" },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  return (
    <div className="pl-4 pr-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
          Sponsors
        </h2>
        <div
          onClick={() => setNewModalOpen(true)}
          onKeyUp={() => setNewModalOpen(true)}
        >
          <ButtonLeadingIcon
            icon="PlusCircleIcon"
            text="Add Sponsor"
            className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          />
        </div>
      </div>
      {isError ? "Failed to load" : ""}
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="mt-2 flex h-screen">
          <div className="flex-grow">
            <DataGridPro
              rows={rows}
              columns={columns}
              onCellDoubleClick={(
                params: GridCellParams,
                event: MuiEvent<MouseEvent>,
              ) => {
                event.defaultMuiPrevented = true
                setSponsorId(params.row.id)
                setEditModalOpen(true)
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "updated_at", sort: "desc" }],
                },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </div>
        </div>
      )}
      {newModalOpen && (
        <SponsorNewModal setOpen={setNewModalOpen} refreshData={mutate} />
      )}
      {editModalOpen && sponsorId && (
        <SponsorEditModal
          sponsorId={sponsorId}
          setOpen={setEditModalOpen}
          refreshData={mutate}
        />
      )}
    </div>
  )
}
