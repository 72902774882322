const defaultTheme = require("tailwindcss/defaultTheme")
const plugin = require("tailwindcss/plugin")

const scrollbarPlugin = plugin(({ addUtilities }) => {
  //  Hides scrollbar for Chrome, Safari and Opera, IE, Edge and Firefox
  addUtilities({
    ".no-scrollbar::-webkit-scrollbar": {
      display: "none",
    },
    ".no-scrollbar": {
      "-ms-overflow-style": "none" /* IE and Edge */,
      "scrollbar-width": "none" /* Firefox */,
    },
  })
})

module.exports = {
  content: [
    "./src/**/*.{html,js,ts,jsx,tsx}",
    "./node_modules/flowbite/**/*.js",
  ],
  theme: {
    extend: {
      colors: {
        "topo-blue": {
          DEFAULT: "#333D7C",
          50: "#f2f5fc",
          100: "#e1e8f8",
          200: "#cad7f3",
          300: "#a6beea",
          400: "#7b9cdf",
          500: "#5c7bd5",
          600: "#4860c8",
          700: "#3e4fb7",
          800: "#374296",
          900: "#333d7c",
          950: "#222649",
        },
        "topo-navy": {
          DEFAULT: "#031941",
          50: "#eaf8ff",
          100: "#d1efff",
          200: "#ace3ff",
          300: "#73d5ff",
          400: "#32baff",
          500: "#0395ff",
          600: "#006dff",
          700: "#0053ff",
          800: "#0044da",
          900: "#003faa",
          950: "#031941",
        },
        "topo-green": {
          DEFAULT: "#0FC0A4",
          100: "#B1E6DD",
          200: "#83DCCE",
          300: "#59D3C0",
          400: "#33C9B2",
          500: "#0FC0A4",
          600: "#12AB93",
          700: "#13917D",
          800: "#137969",
          900: "#126154",
        },
      },
      fontFamily: {
        sans: ["ui-sans-serif", ...defaultTheme.fontFamily.sans],
      },
      transitionProperty: {
        height: "height",
        spacing: "margin, padding",
        width: "width",
      },
    },
  },
  variants: {},
  plugins: [
    require("@tailwindcss/forms"),
    require("flowbite/plugin"),
    require("@tailwindcss/typography"),
    scrollbarPlugin,
  ],
}
