import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  GridToolbar,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { Tooltip } from "flowbite-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { Button } from "../../../components/button"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { HeroIcon } from "../../../components/hero-icon"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"
import { UserEditModal } from "./edit-modal"
import { UserNewModal } from "./new-modal"
import { PracticeUsers, PracticeUsersIndex } from "./practice_users"
import { PracticeUsersDisplay } from "./practice_users/display"

export function Users() {
  const { data, isLoading, isError, mutate } = useIndex("admin/users")
  const [newModalOpen, setNewModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [userId, setUserId] = useState("")
  const navigate = useNavigate()

  const handleShowUser = (userId) => navigate(`/admin/users/${userId}`)

  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "prefix", headerName: "Prefix", width: 50 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    // { field: 'slack_username', headerName: 'Slack', width: 150 },
    { field: "role", headerName: "Role", width: 150 },
    {
      field: "task_user_xref_id",
      headerName: "Monday.com User Id",
      width: 200,
    },
    {
      field: "phone_extension_xref_id",
      headerName: "Ring Central Extension",
      width: 200,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 150,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 150,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
    { field: "deleted_at", headerName: "Deleted At", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      display: "flex",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params, handleShowUser),
    },
  ]

  const renderCellActions = (props: GridCellParams, handleShowUser) => {
    return (
      <button
        type="button"
        onClick={() => handleShowUser(props.row.id)}
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Edit
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  return (
    <div className="pl-4 pr-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
          Users
        </h2>
        {isLoading ? "Loading.." : ""}
        {isError ? "Failed to load" : ""}

        <div
          onClick={() => setNewModalOpen(true)}
          onKeyUp={() => setNewModalOpen(true)}
        >
          <ButtonLeadingIcon
            icon="PlusCircleIcon"
            text="Add User"
            className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          />
        </div>
      </div>

      <div className="mt-2 flex h-screen">
        <div className="flex-grow">
          <DataGridPro
            rows={rows}
            columns={columns}
            getDetailPanelContent={({ row }) => (
              <PracticeUsersIndex userId={row.id} />
            )}
            onCellDoubleClick={(
              params: GridCellParams,
              event: MuiEvent<MouseEvent>,
            ) => {
              event.defaultMuiPrevented = true
              handleShowUser(params.row?.id)
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      </div>
      {newModalOpen && (
        <UserNewModal setOpen={setNewModalOpen} refreshData={mutate} />
      )}
      {editModalOpen && userId && (
        <UserEditModal
          id={userId}
          setOpen={setEditModalOpen}
          refreshData={mutate}
        />
      )}
    </div>
  )
}
