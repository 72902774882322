import { DataGrid, type GridColDef, GridRowsProp } from "@mui/x-data-grid"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { mutate } from "swr"
import { ActionHeader } from "../../../components/action-header"
import { Button } from "../../../components/button"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { HeroIcon } from "../../../components/hero-icon"
import { MapWithSingleMarker } from "../../../components/map-with-single-marker"
import { useCreate, useIndex, useShow } from "../../../shared/use-rest"
import { PracticeEditModal } from "./edit-modal"

import { useAuth0 } from "@auth0/auth0-react"
import { Badge, Card, Tooltip } from "flowbite-react"
import { useForm } from "react-hook-form"
import { MapWithMultipleMarkers } from "../../../components/map-with-multiple-markers"
import { Spinner } from "../../../components/spinner"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { PracticeLocationEditModal } from "./locations/edit-modal"
import { PracticeLocationNewModal } from "./locations/new-location-modal"
import { PracticeStudiesDisplay } from "./studies/display"
import { PracticeStudyEditModal } from "./studies/edit-modal"
import { PracticeStudyNewModal } from "./studies/new-modal"
import { PracticeUsersDisplay } from "./users/display"
import { PracticeUserEditModal } from "./users/edit-modal"
import { PracticeUserNewModal } from "./users/new-modal"

export function Practice() {
  const { practiceId } = useParams()
  const {
    data: practice,
    isLoading,
    isError,
    mutate: mutatePractice,
  } = useShow("admin/practices", practiceId)
  const {
    data: practice_locations,
    isLoading: practiceLocationsIsLoading,
    mutate: mutateLocations,
  } = useIndex("admin/practice_locations", `practice_id=${practiceId}`)

  const [practiceLocation, setPracticeLocation] = useState()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [newLocationModalOpen, setNewLocationModalOpen] = useState(false)
  const [editLocationModalOpen, setEditLocationModalOpen] = useState(false)

  const [addingStudy, setAddingStudy] = useState(false)
  const { handleSubmit, register, reset, setValue, control } = useForm()
  const { getAccessTokenSilently } = useAuth0()

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "address", headerName: "Address", width: 150 },
    { field: "latitude", headerName: "Latitude", width: 150 },
    { field: "longitude", headerName: "Longitude", width: 150 },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  const handleRefresh = () => {
    mutatePractice()
    mutateLocations()
  }

  return (
    <>
      <div>
        <div className="flex justify-between items-center pr-4 pl-4">
          <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
            {practice.name}
          </h2>
          <div
            onClick={() => setEditModalOpen(true)}
            onKeyUp={() => setEditModalOpen(true)}
          >
            <ButtonLeadingIcon
              icon="PencilIcon"
              text="Edit Detail"
              className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            />
          </div>
        </div>
      </div>

      {isError ? "Failed to load" : ""}
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="pl-4 pr-4">
          <Card>
            <h3 className="font-bold text-lg">Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Name
                  </Badge>
                  <div className="ml-2">
                    {practice.name || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Abbreviation
                  </Badge>
                  <div className="ml-2">
                    {practice.abbreviation || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Address
                  </Badge>
                  <div className="ml-2">
                    {practice.address || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Esource Practice Slug
                  </Badge>
                  <div className="ml-2">
                    {practice.xref_esource_practice_slug || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Waypoints Practice Slug
                  </Badge>
                  <div className="ml-2">
                    {practice.waypoints_practice_slug || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Latitude
                  </Badge>
                  <div className="ml-2">
                    {practice.latitude || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Longitude
                  </Badge>
                  <div className="ml-2">
                    {practice.longitude || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Monday.com Workspace id
                  </Badge>
                  <div className="ml-2">
                    {practice.task_workspace_xref_id || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Monday.com tasks folder id
                  </Badge>
                  <div className="ml-2">
                    {practice.task_folder_xref_id || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Monday.com practice team id
                  </Badge>
                  <div className="ml-2">
                    {practice.task_team_xref_id || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-1">
                  <Badge color="gray" size="sm">
                    Provider Support Phone Number
                  </Badge>
                  <div className="ml-2">
                    {practice.provider_support_phone_number || (
                      <span className="text-gray-400 italic">Not provided</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}

      <PracticeStudiesDisplay />
      <PracticeUsersDisplay />
      <div className="mt-2 p-4">
        <div>
          <div className="flex justify-between items-center ">
            <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
              Locations
            </h2>
            <div
              onClick={() => setNewLocationModalOpen(true)}
              onKeyUp={() => setNewLocationModalOpen(true)}
            >
              <ButtonLeadingIcon
                icon="PlusCircleIcon"
                text="Add Practice Location"
                className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              />
            </div>
          </div>
        </div>

        {practiceLocationsIsLoading ? (
          <Spinner />
        ) : (
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
            <thead className="bg-gray-50 dark:bg-gray-700">
              <tr>
                <th
                  scope="col"
                  className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
                >
                  Location Name
                </th>
                <th
                  scope="col"
                  className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
                >
                  Latitude
                </th>
                <th
                  scope="col"
                  className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
                >
                  Longitude
                </th>
                <th
                  scope="col"
                  className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800">
              {practice_locations.map((practice_location, index) => (
                <tr
                  key={practice_location.id}
                  className={index % 2 !== 0 ? "bg-gray-50" : ""}
                >
                  <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                    <span className="text-sm font-medium ">
                      {practice_location.name}
                    </span>
                  </td>
                  <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                    {practice_location.address}
                  </td>
                  <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                    {practice_location.latitude}
                  </td>
                  <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                    {practice_location.longitude}
                  </td>
                  <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                    <button
                      type="button"
                      onClick={() => {
                        setPracticeLocation(practice_location)
                        setEditLocationModalOpen(true)
                      }}
                      className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
                    >
                      Edit
                      <HeroIcon
                        icon="ArrowCircleRightIcon"
                        className="ml-1 w-5 h-5 flex"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {practice?.latitude && practice?.longitude && (
        <MapWithMultipleMarkers
          defaultZoom={8.5}
          center={{
            latitude: practice.latitude,
            longitude: practice.longitude,
          }}
          markers={practice_locations.map((practice_location) => ({
            latitude: practice_location.latitude,
            longitude: practice_location.longitude,
            title: practice_location.name,
            showTitle: true,
          }))}
        />
      )}
      {editModalOpen && practiceId && (
        <PracticeEditModal
          practiceId={practiceId}
          setOpen={setEditModalOpen}
          refreshData={handleRefresh}
        />
      )}
      {newLocationModalOpen && practiceId && (
        <PracticeLocationNewModal
          practiceId={practiceId}
          setOpen={setNewLocationModalOpen}
          refreshData={mutateLocations}
        />
      )}
      {editLocationModalOpen && practiceId && (
        <PracticeLocationEditModal
          practiceLocation={practiceLocation}
          practiceId={practiceId}
          setOpen={setEditLocationModalOpen}
          refreshData={mutateLocations}
        />
      )}
    </>
  )
}
