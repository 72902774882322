import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useEffect, useState } from "react"
import type { MouseEvent } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { Button } from "../../../components/button"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { HeroIcon } from "../../../components/hero-icon"
import { MapWithMultipleMarkers } from "../../../components/map-with-multiple-markers"
import { Spinner } from "../../../components/spinner"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"
import { PracticeEditModal } from "./edit-modal"
import { PracticeNewSlideover } from "./new"

export function Practices() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [newSlideoverOpen, setNewSlideoverOpen] = useState(
    searchParams.get("new") === "true",
  )
  const [editModalOpen, setEditModalOpen] = useState(
    searchParams.get("practice_id") !== undefined,
  )
  const [markers, setMarkers] = useState([])
  const [practiceId, setPracticeId] = useState()
  const navigate = useNavigate()
  const { data, isLoading, isError, mutate } = useIndex("admin/practices")
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 150, display: "flex" },
    { field: "address", headerName: "Address", width: 350, display: "flex" },
    {
      field: "xref_esource_practice_slug",
      headerName: "Esource Practice Slug",
      width: 350,
      display: "flex",
    },
    {
      field: "provider_support_phone_number",
      headerName: "Provider Support Phone Number",
      width: 350,
      display: "flex",
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      display: "flex",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params, handleShowPractice),
    },
  ]
  function toggleNewSlideoverOpen() {
    setNewSlideoverOpen(true)
  }

  const renderCellActions = (props: GridCellParams, handleShowPractice) => {
    return (
      <button
        type="button"
        onClick={() => handleShowPractice(props.row.id)}
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Edit
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  const handleShowPractice = (practiceId) =>
    navigate(`/admin/practices/${practiceId}`)

  useEffect(() => {
    setMarkers(
      data
        .map(
          (practice) =>
            practice?.latitude &&
            practice?.longitude && {
              title: practice.name,
              latitude: practice.latitude,
              longitude: practice.longitude,
              showTitle: false,
            },
        )
        .filter((marker) => marker !== null),
    )
  }, [data])

  return (
    <>
      <ActionHeader title="Practices">
        <div
          className="flex"
          onClick={toggleNewSlideoverOpen}
          onKeyUp={toggleNewSlideoverOpen}
        >
          <ButtonLeadingIcon icon="PlusCircleIcon" text="Add Practice" />
        </div>
      </ActionHeader>
      {isError ? "Failed to load" : ""}
      {markers && <MapWithMultipleMarkers markers={markers} />}
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="mt-2 flex h-screen pl-4 pr-4">
            <div className="flex-grow">
              <DataGridPro
                rows={rows}
                columns={columns}
                onCellDoubleClick={(
                  params: GridCellParams,
                  event: MuiEvent<MouseEvent>,
                ) => {
                  event.defaultMuiPrevented = true
                  handleShowPractice(params.row?.id)
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          </div>
          <PracticeNewSlideover
            open={newSlideoverOpen}
            setOpen={setNewSlideoverOpen}
            refreshData={mutate}
          />
        </div>
      )}
      {editModalOpen && practiceId && (
        <PracticeEditModal
          practiceId={practiceId}
          setOpen={setEditModalOpen}
          refreshData={mutate}
        />
      )}
    </>
  )
}
