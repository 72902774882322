import { useAuth0 } from "@auth0/auth0-react"
import { useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import {
  useCreate,
  useDelete,
  useIndex,
  useShow,
  useUpdate,
} from "../../../../shared/use-rest"
import { StudyBuilderHeader } from "./header"
import { StudyBuilderQuestion } from "./question"
import { QuestionsSlideover } from "./questions-slideover"

export function StudyBuilder() {
  const { studyId } = useParams()
  const { getAccessTokenSilently } = useAuth0()
  const { data: study } = useShow("admin/studies", studyId)
  const { data: study_questions, mutate: mutateStudyQuestions } = useIndex(
    "admin/study_questions",
    `study_id=${studyId}`,
  )
  const { data: questions, mutate: mutateQuestions } =
    useIndex("admin/questions")
  const [questionsSlideoverOpen, setQuestionsSlideoverOpen] = useState(false)

  const maxOrder = () => {
    if (study_questions?.length === 0) return 0
    const max = study_questions?.slice()?.sort((a, b) => b.order - a.order)[0]
    return max?.order || 0
  }

  const addStudyQuestion = async (studyQuestion: object) => {
    // console.log('addQuestion')
    // console.dir({study_id: study.id, question_id: id, order: maxOrder() + 1})
    const studyQuestionData = {
      study_id: study.id,
      question_id: studyQuestion.question_id,
      order: studyQuestion?.order || maxOrder() + 1,
      exclusion_criteria: studyQuestion?.exclusion_criteria || false,
    }
    await useCreate(
      "study_questions",
      studyQuestionData,
      getAccessTokenSilently,
    )
    toast.success("Question Added to Study")
    mutateStudyQuestions()
  }

  const addQuestion = async (fullText) => {
    return await useCreate(
      "admin/questions",
      { full_text: fullText },
      getAccessTokenSilently,
    )
  }

  const deleteStudyQuestion = async (id) => {
    await useDelete("admin/study_questions", id, getAccessTokenSilently)
    mutateStudyQuestions()
  }

  const updateStudyQuestion = async (id, data) => {
    await useUpdate("admin/study_questions", id, data, getAccessTokenSilently)
    mutateStudyQuestions()
  }

  const decrementQuestionOrder = async (currentId: string) => {
    const order = study_questions.find(
      (studyQuestion) => studyQuestion.id === currentId,
    )?.order
    if (order - 1 === 0) return
    const previousQuestion = study_questions.find(
      (studyQuestion) => studyQuestion.order === order - 1,
    )
    if (!previousQuestion.id) return
    updateStudyQuestion(currentId, { order: order - 1 })
    updateStudyQuestion(previousQuestion.id, {
      order: previousQuestion.order + 1,
    })
  }

  const incrementQuestionOrder = async (currentId: string) => {
    const order = study_questions.find(
      (studyQuestion) => studyQuestion.id === currentId,
    )?.order
    if (order === maxOrder()) return
    const nextQuestion = study_questions.find(
      (studyQuestion) => studyQuestion.order === order + 1,
    )
    if (!nextQuestion?.id) return
    updateStudyQuestion(currentId, { order: order + 1 })
    updateStudyQuestion(nextQuestion?.id, { order: nextQuestion?.order - 1 })
  }

  return (
    <>
      <ActionHeader title="StudyBuilder" />
      <StudyBuilderHeader study={study} />
      <NewQuestionButton
        setQuestionsSlideoverOpen={setQuestionsSlideoverOpen}
      />
      <div className="text-sm font-bold m-4">
        Total Number: {study_questions?.length}
      </div>

      <div className="text-sm font-bold m-4">
        Last Updated:{" "}
        {friendlyDateTime(
          study_questions.reduce((max, obj) => {
            const currentDate = new Date(`${obj.updated_at}Z`)
            return currentDate > max ? currentDate : max
          }, new Date(0)),
        )}
      </div>
      <QuestionsSlideover
        open={questionsSlideoverOpen}
        setOpen={setQuestionsSlideoverOpen}
        refreshData={() => mutateQuestions()}
        questions={questions}
        addStudyQuestion={addStudyQuestion}
        addQuestion={addQuestion}
      />
      <div className="mt-2 flex h-screen">
        <div className="flex-grow">
          {study_questions?.map((question) => (
            <StudyBuilderQuestion
              key={question.id}
              question={question}
              deleteStudyQuestion={deleteStudyQuestion}
              updateStudyQuestion={updateStudyQuestion}
              decrementQuestionOrder={decrementQuestionOrder}
              incrementQuestionOrder={incrementQuestionOrder}
              maxOrder={maxOrder}
            />
          ))}
        </div>
      </div>
    </>
  )
}

const NewQuestionButton = (props) => (
  <div
    className="p-4 inline-flex"
    onClick={() => props.setQuestionsSlideoverOpen(true)}
    onKeyUp={() => props.setQuestionsSlideoverOpen(true)}
  >
    <ButtonLeadingIcon
      className="bg-topo-blue hover:bg-blue-800 focus:ring-blue-500"
      icon="PlusCircleIcon"
      text="Add Question"
    />
  </div>
)
