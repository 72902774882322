import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useState } from "react"
import type { MouseEvent } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { Spinner } from "../../../components/spinner"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"
import { StudyEditModal } from "./edit-modal"
import { StudyNewSlideover } from "./new-slideover"

export function StudiesAdmin() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [newSlideoverOpen, setNewSlideoverOpen] = useState(
    searchParams.get("new") === "true",
  )
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [studyId, setStudyId] = useState("")
  const { data, isLoading, isError, mutate } = useIndex("admin/studies")
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 150, display: "flex" },
    { field: "nickname", headerName: "Nickname", width: 150, display: "flex" },
    {
      field: "abbreviation",
      headerName: "Abbreviation",
      width: 150,
      display: "flex",
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      display: "flex",
    },
    {
      field: "study_type",
      headerName: "Study Type",
      width: 150,
      display: "flex",
    },
    {
      field: "protocol_version",
      headerName: "Protocol Version",
      width: 150,
      display: "flex",
    },
    { field: "compound", headerName: "Compound", width: 150, display: "flex" },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  function toggleNewSlideoverOpen() {
    setNewSlideoverOpen(true)
  }

  return (
    <>
      <div className="flex justify-between items-center pr-4">
        <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
          Studies
        </h2>
        <div onClick={toggleNewSlideoverOpen} onKeyUp={toggleNewSlideoverOpen}>
          <ButtonLeadingIcon
            icon="PlusCircleIcon"
            text="Add Study"
            className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          />
        </div>
      </div>
      {isError ? "Failed to load" : ""}
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="mt-2 flex h-screen">
          <div className="flex-grow">
            <DataGridPro
              rows={rows}
              columns={columns}
              onCellDoubleClick={(
                params: GridCellParams,
                event: MuiEvent<MouseEvent>,
              ) => {
                event.defaultMuiPrevented = true
                navigate(`/admin/studies/${params.row.id}`)
                // setStudyId(params.row.id)
                // setEditModalOpen(true)
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </div>
        </div>
      )}
      <StudyNewSlideover
        open={newSlideoverOpen}
        setOpen={setNewSlideoverOpen}
        refreshData={mutate}
      />
      {editModalOpen && (
        <StudyEditModal
          studyId={studyId}
          setOpen={setEditModalOpen}
          refreshData={mutate}
        />
      )}
    </>
  )
}
