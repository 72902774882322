import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { HeroIcon } from "../../../../components/hero-icon"
import { Spinner } from "../../../../components/spinner"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { useIndex } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { StudyEditModal } from "../edit-modal"
import { StudyNewSlideover } from "../new-slideover"

export function Visits() {
  const { studyId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [editModalOpen, setEditModalOpen] = useState(false)
  const {
    data: { data: studyVisits },
    isFetching,
    isError,
  } = useIndexQuery("admin/study_visits", `study_id=${studyId}`)

  const rows: GridRowsProp = studyVisits
  const columns: GridColDef[] = [
    {
      field: "visit_name",
      headerName: "Visit Name",
      width: 200,
      display: "flex",
    },
    {
      field: "xref_study_visit_order",
      headerName: "Order",
      width: 100,
      display: "flex",
    },
    {
      field: "xref_study_visit_number",
      headerName: "Visit #",
      width: 100,
      display: "flex",
    },
    {
      field: "xref_study_visit_type",
      headerName: "Type",
      width: 120,
      display: "flex",
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      display: "flex",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params, handleShowStudyVisit),
    },
  ]

  const renderCellActions = (props: GridCellParams, handleShowStudyVisit) => {
    return (
      <button
        type="button"
        onClick={() =>
          handleShowStudyVisit(props.row.id, props.row.task_template_id)
        }
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Edit
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  const handleShowStudyVisit = (visitId, taskTemplateId) => {
    navigate(`/admin/studies/${studyId}/visits/${visitId}`)
  }

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <div className="mt-2 flex min-h-[200px] pr-4">
          <div className="flex-grow">
            <DataGridPro
              rows={rows}
              columns={columns}
              onCellDoubleClick={(
                params: GridCellParams,
                event: MuiEvent<MouseEvent>,
              ) => {
                event.defaultMuiPrevented = true
                navigate(`/admin/studies/${studyId}/visits`)
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}
